html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

/* body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
} */

#root {
  padding: 0;
  margin: 0;
}

.form-control {
  margin-bottom: 10px;
}

.right {
  float: right;
}

.left {
  float: left;
}

.small {
  font-size: 12pt;
}

article {
  padding: 10px;
}

footer {
  padding: 16px;
}

#headerFixer {
  height: 148px;
}

#menuRightPart {
  position: absolute;
  top: 20px;
  right: 32px;
}

.pointer {
  cursor: pointer !important;
}

.left {
  float: left;
}

.editorFormControll {
  width: 100%;
  background: transparent;
  border: none;
}

.orderFormControll {
  width: 100%;
  background: transparent;
  border: none;
  text-align: right;
  width: 64px;
  outline: none;
}

.center {
  text-align: center;
}

.totalPriceDiv {
  margin-right: 32px;
}

.indexOffset {
  margin-right: 32px;
}

.buttonOffset {
  margin-right: 10px;
}

.hidden {
  display: none;
}

#deliverMap {
  border: none;
  width: 100%;
  height: 50vh;
}

#products td,
#products th,
#productsRecap td,
#productsRecap th {
  padding: 5px 10px;
}

#recapitulation {
  padding: 32px;
}

#giftsCont ul {
  list-style-type: none;
}

input:read-only {
  background-color: white !important;
}

.text-right {
  text-align: right;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  opacity: 1;
}

input.no-arrows::-webkit-outer-spin-button,
input.no-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input.no-arrows[type="number"] {
  -moz-appearance: textfield;
}

.btn-group-toggle > .btn-primary:not(.actice) {
  background-color: grey;
}

.addContainer {
  margin: 32px 0;
  width: 100%;
  text-align: center;
}

.addContainer > img {
  width: 80%;
}

.editorIsHighlighted {
  outline: 2px solid rgb(255, 0, 102);
  box-shadow: none;
}

.giftCol {
  padding-top: 8px;
  padding-left: 32px;
}

/*medium*/
@media (max-width: 767px) {
  #headerTitle {
    font-size: 32px;
  }
  #headerSubtitle {
    display: none;
  }
}

/*small*/
@media (max-width: 576px) {
  #headerTitle {
    font-size: 28px;
  }
  #headerSubtitle {
    display: none;
  }
}

/*Extra small*/
@media (max-width: 412px) {
  #headerTitle {
    font-size: 22px;
  }
  #headerSubtitle {
    display: none;
  }
}

@media (max-width: 370px) {
  #headerTitle {
    font-size: 20px;
  }
  #headerSubtitle {
    display: none;
  }
}

/*Super extra small*/
@media (max-width: 330px) {
  #headerTitle {
    font-size: 18px;
  }
  #headerSubtitle {
    display: none;
  }
  h5 {
    font-size: 1.4rem;
  }
}

/*Super extra small*/
@media (max-width: 315px) {
  #headerTitle {
    font-size: 16px;
  }
  #headerSubtitle {
    display: none;
  }
  h5 {
    font-size: 1.4rem;
  }
}
